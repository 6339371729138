const api = `${process.env.REACT_APP_PUBLIC_URL}/api`;
export const ChartistApi = `${api}/chartistData.json`;
export const ChartjsApi = `${api}/chartjsData.json`;
export const ProductsApi = `${api}/product.json`;
export const GooglechartApi = `${api}/googleChartData.json`;
export const AllProjectApi = `${api}/allProject.json`;

const backendApi = `${process.env.REACT_APP_BACKEND_URL}`;
export const LoginApi = `${backendApi}/jwt-auth/v1/token`;
export const LoadsApi = {
    getAll: `${backendApi}/invst/loads`,
};
export const UserApi = {
    getAll: `${backendApi}/invst/user`,
    updateBanking: `${backendApi}/invst/user/updateBanking`,
    updatePersonalData: `${backendApi}/invst/user/updatePersonalData`,
    updatePassword: `${backendApi}/invst/user/updatePassword`,
    updateAvatar: `${backendApi}/invst/user/updateAvatar`,
    deleteAvatar: `${backendApi}/invst/user/deleteAvatar`,
};
export const TranactionsApi = {
    getAll: `${backendApi}/invst/transactions/my`,
    create: `${backendApi}/invst/transactions/create`,
    check: `${backendApi}/invst/transactions/check`,
};
export const RequestsApi = {
    getAll: `${backendApi}/invst/requests/my`,
    create: `${backendApi}/invst/request/create`,
};

export const RatingsApi = {
    getAll: 'https://cb.am/latest.json.php',
}