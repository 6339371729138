import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Btn, Ribbon, Spinner } from '../../../AbstractElements';
import RatingsContext from '../../../_helper/Ratings';
import TransactionContext from '../../../_helper/Transactions';
import UserContext from '../../../_helper/User';

const Balance = () => {
    const history = useNavigate();

    const { balanceMain } = useContext(UserContext);

    const { newTransactionId, createPayment } = useContext(TransactionContext);

    useEffect(() => {
        if (0 != newTransactionId) {
            history(`${process.env.PUBLIC_URL}/result/${newTransactionId}`);
        }
    }, [newTransactionId])

    const [amountAMD, setAmountAMD] = useState('');
    const [amountUSD, setAmountUSD] = useState('');
    const [amountRUB, setAmountRUB] = useState('');
    const [loading, setLoading] = useState(false);

    const { ratings, getAllRatingsData } = useContext(RatingsContext);
    useEffect(() => {
        getAllRatingsData();
    }, [getAllRatingsData]);

    const [balanceModal, setBalanceModal] = useState(false);
    const openBalanceModal = () => {
        setAmountAMD('');
        setAmountUSD('');
        setAmountRUB('');
        setBalanceModal(true);
    };

    const closeBalanceModal = () => {
        setBalanceModal(false);
    };

    const setAmounts = (amount, currency) => {
        let amounts = {
            USD: 0,
            RUB: 0,
            AMD: 0,
        }

        if (undefined !== amounts[currency]) {
            Object.keys(amounts).forEach((c) => {
                let coef = ratings[currency] / ratings[c];
                amounts[c] = Math.ceil((amount * coef) * 100) / 100;
            });
            setAmountAMD(amounts.AMD);
            setAmountUSD(amounts.USD);
            setAmountRUB(amounts.RUB);
        }
    }

    const onSubmit = async () => {

        if (amountAMD > 0) {
            setLoading(true);
            let result = await createPayment(amountAMD);
            setLoading(false);
            if (true == result) {
                toast.success("Transaction created");
                // closeBalanceModal();
            } else {
                toast.error('Something went wrong...');
            }
        } else {
            setLoading(false);
            toast.error('Amount in AMD must be positive');
        }
    }

    return (
        <Fragment>
            <Card className='ribbon-wrapper'>
                <CardBody>
                    <Ribbon attrRibbons={{ className: 'ribbon ribbon-clip ribbon-info' }} >Balance</Ribbon>
                    <div className='d-flex align-items-center flex-wrap' style={{ gap: '20px' }}>
                        <b className="h2 m-0 txt-primary">֏{balanceMain}</b>
                        <button className="btn btn-primary px-2" onClick={() => { openBalanceModal() }}><i className="icofont icofont-plus-circle h3"></i></button>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={balanceModal} toggle={closeBalanceModal} size='md' centered>
                <ModalHeader toggle={closeBalanceModal}>
                    Add to balance
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="row mx-0">
                        <Label className="col-sm-3 col-form-label">{'AMD'}</Label>
                        <Col sm="9">
                            <Input className="form-control digits" type="number" placeholder="Amount AMD" value={amountAMD} onChange={(e) => { setAmounts(e.target.value, 'AMD') }} />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row mx-0">
                        <Label className="col-sm-3 col-form-label">{'RUB'}</Label>
                        <Col sm="9">
                            <Input className="form-control digits" type="number" placeholder="Amount RUB" value={amountRUB} onChange={(e) => { setAmounts(e.target.value, 'RUB') }} />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row mx-0">
                        <Label className="col-sm-3 col-form-label">{'USD'}</Label>
                        <Col sm="9">
                            <Input className="form-control digits" type="number" placeholder="Amount USD" value={amountUSD} onChange={(e) => { setAmounts(e.target.value, 'USD') }} />
                        </Col>
                    </FormGroup>
                    <div className='row mx-0'>
                        <div className='col-12 mb-2'>
                            <small className='txt-dark'><i className="icofont icofont-info-circle"></i> Payment will be created in AMD currency!</small>
                        </div>
                        <div className='col-12'>
                            <div className='form-footer d-flex align-items-center'>
                                <Btn attrBtn={{ onClick: onSubmit, className: 'btn-block btn-lg', color: 'primary' }}>{'Submit'}</Btn>
                                {loading ?
                                    <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                                        <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment >
    );
};

export default Balance;
