import axios from 'axios';
import React, { useState } from 'react';
import { TranactionsApi } from '../../api';
import Context from './index';

const TransactionProvider = (props) => {
    const [allData, setAllData] = useState([]);

    const [newTransactionId, setNewTransactionId] = useState(0);
    const [transactionStatus, setTransactionStatus] = useState('');

    const getAllTransactionsData = async () => {
        try {
            await axios.get(TranactionsApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setAllData(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    const createPayment = async (amount) => {
        try {
            await axios.post(TranactionsApi.create, {
                amount: amount,
                type: 'balance_refill',
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setNewTransactionId(resp.data);
                setTransactionStatus('');
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const createWithdraw = async (amount) => {
        try {
            await axios.post(TranactionsApi.create, {
                amount: amount,
                type: 'withdraw',
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setAllData([resp.data, ...allData]);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const checkTransactionStatus = async (id) => {
        try {
            await axios.post(TranactionsApi.check, {
                id: id
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data?.status) {
                    // setTransactionStatus('');
                    setTransactionStatus(resp.data?.status);
                }
                setNewTransactionId(0);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    }

    const resetNewTransactionId = () => {
        setNewTransactionId(0);
    }

    return (
        <Context.Provider
            value={{
                ...props,
                createPayment,
                createWithdraw,
                getAllTransactionsData,
                resetNewTransactionId,
                checkTransactionStatus,
                allData,
                newTransactionId,
                transactionStatus,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default TransactionProvider;