import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import UserContext from '../../../_helper/User';
import EditPassword from './EditPassword';
import UserBanking from './UserBanking';
import UserProfile from './UserProfile';

const Account = () => {
  const history = useNavigate();

  const { getCurrentUserData } = useContext(UserContext);
  useEffect(() => {
    getCurrentUserData();
  }, []);

  const [passwordModal, setPasswordModal] = useState(false);
  const openPasswordModal = () => {
    setPasswordModal(true);
  };

  const closePasswordModal = () => {
    setPasswordModal(false);
  };

  const Logout = () => {
    localStorage.removeItem('profileURL');
    localStorage.removeItem('token');
    localStorage.removeItem('auth0_profile');
    localStorage.removeItem('Name');
    localStorage.setItem('authenticated', false);
    history(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle={(<div className='d-flex justify-content-between'>Edit Profile <Btn attrBtn={{ color: 'danger', className: 'btn', type: 'button', onClick: () => { Logout() } }}>Logout</Btn></div>)} parent='Users' title='Edit Profile' />
      <Container fluid={true} className='user-profile'>
        <div className='edit-profile'>
          <Row>
            <Col xl='6'>
              <UserProfile />
            </Col>
            <Col xl='6'>
              <UserBanking />
              <Btn attrBtn={{ color: 'primary', className: 'btn', type: 'button', onClick: () => { openPasswordModal() } }}>Change Password</Btn>
            </Col>
            <Col xl='4'>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal isOpen={passwordModal} toggle={closePasswordModal} size='lg' centered>
        <ModalHeader toggle={closePasswordModal}>
          Edit Password
        </ModalHeader>
        <ModalBody>
          <EditPassword closePasswordModal={closePasswordModal} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default Account;
