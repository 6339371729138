import React, { Fragment, useContext, useState } from 'react';
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Image, Spinner } from '../../../AbstractElements';
import UserContext from '../../../_helper/User';


const UserImage = () => {

  const { imageUrl, updateCurrentUserAvatar, deleteCurrentUserAvatar } = useContext(UserContext);

  const [url, setUrl] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
    };
  };

  const readFile = (event) => {
    if (event.target.files.length === 0) return;
    setFile(event.target.files[0]);
    readUrl(event);
  }

  const [imageModal, setImageModal] = useState(false);
  const openImageModal = () => {
    setFile(null);
    setUrl('');
    setLoading(false);
    setImageModal(true);
  };

  const closeImageModal = () => {
    setImageModal(false);
  };

  const onSubmit = async () => {
    if (!!file) {
      setLoading(true);
      let result = await updateCurrentUserAvatar(file);
      if (true == result) {
        setLoading(false);
        toast.success("Avatar updated");
        closeImageModal();
      } else {
        toast.error('Something went wrong...');
      }
    }
    // console.log('onSubmit', file);
  }

  const removeAvatar = async () => {
    let confirmed = window.confirm('Are you sure to delete existing avatar?');
    if (confirmed) {
      setLoading(true);
      let result = await deleteCurrentUserAvatar();
      if (true == result) {
        setLoading(false);
        toast.success("Avatar deleted");
        closeImageModal();
      } else {
        toast.error('Something went wrong...');
      }
    }
  }

  return (
    <Fragment>
      <div className='user-image d-flex' style={{ height: 'auto' }}>
        <div className='btn border-0 avatar m-0 position-relative p-0' onClick={(e) => openImageModal()}>
          <Image attrImage={{ className: 'step1', alt: '', src: `${imageUrl ? imageUrl : require('../../../assets/images/user/avatar.jpg')}` }} />
          <div className='icon-wrapper step2' style={{ bottom: 0, left: 'unset', right: 0 }} data-intro='Change Profile image here'>
            <i className='icofont icofont-pencil-alt-5'></i>
          </div>
        </div>
      </div>

      <Modal className='user-profile' isOpen={imageModal} toggle={closeImageModal} size='md' centered>
        <ModalHeader toggle={closeImageModal}>
          Edit My Avatar
        </ModalHeader>
        <ModalBody className='hovercard'>
          <div className='row justify-content-between align-items-center mx-0 mb-4'>
            <div className='col-6'>
              {
                url ?
                  <div className='user-image d-flex justify-content-center' style={{ height: 'auto' }}>
                    <div className='avatar m-0 position-relative'>
                      <Image attrImage={{ className: 'step1', alt: '', src: `${url}` }} />
                      <button className='btn btn-pill btn-sm btn-danger py-1 px-2 position-absolute end-0 top--0'
                        onClick={() => { setFile(null); setUrl(''); }} type='button'>
                        <i className="icofont icofont-ui-close"></i>
                      </button>
                    </div>
                  </div>
                  :
                  <>
                    <label className='btn p-5 rounded-3' style={{ bottom: 0, left: 'unset', right: 0, background: 'rgba(115, 102, 255, 0.2)' }} data-intro='Change Profile image'>
                      <span className='h3 fw-bold txt-primary'>Select Image</span>
                      <input className='upload d-none' type='file' onChange={(e) => readFile(e)} />
                    </label>
                  </>
              }
            </div>
            <div className='col-6'>
              <label className='btn p-5 rounded-3' onClick={() => { removeAvatar(); }} style={{ bottom: 0, left: 'unset', right: 0, background: 'rgb(255 0 0 / 20%)' }} data-intro='Remove Profile image'>
                <span className='h3 fw-bold txt-secondary'>Remove Avatar</span>
              </label>
            </div>
          </div>
          <div className='d-flex mt-4 mx-2 px-1 justify-content-between' style={{ gap: '15px' }}>
            <div className='d-flex' style={{ gap: '15px' }}> {null == file ? ''
              : <>
                <button className='btn btn-primary btn-lg' type='button' onClick={onSubmit}>Save</button>
                {loading ?
                  <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                    <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                  </div>
                  : ''
                }
              </>
            }
            </div>
            <button className='btn btn-secondary btn-lg' type='button' onClick={closeImageModal}>Cancel</button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment >
  );
};

export default UserImage;
