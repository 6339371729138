// MAIN
import Account from "../Components/Main/Account";
import DefaultDashboard from "../Components/Main/Dashboard";
import Delivery from "../Components/Main/Delivery";
import FinancesContain from "../Components/Main/Finances";
import Investments from "../Components/Main/Investments";
import LoadContain from "../Components/Main/Load";

import ErrorPage404 from '../Components/Main/ErrorPage404';

export const routes = [

  // MAIN
  { path: `${process.env.PUBLIC_URL}/dashboard/`, Component: <DefaultDashboard /> },
  { path: `${process.env.PUBLIC_URL}/finances/`, Component: <FinancesContain /> },
  // { path: `/invoice/:id`, Component: <Invoice /> },
  { path: `${process.env.PUBLIC_URL}/delivery/`, Component: <Delivery /> },
  { path: `${process.env.PUBLIC_URL}/investments/`, Component: <Investments /> },

  { path: `${process.env.PUBLIC_URL}/load/:id`, Component: <LoadContain /> },
  { path: `${process.env.PUBLIC_URL}/account/`, Component: <Account /> },
  { path: `*`, Component: <ErrorPage404 /> },
];
