import React, { Fragment, useContext, useState } from 'react';
import { toast } from "react-toastify";
import { Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Badges, Btn, Ribbon, Spinner, ToolTip } from '../../../AbstractElements';
import TransactionContext from '../../../_helper/Transactions';
import UserContext from '../../../_helper/User';

const BalanceAvailable = ({ getCurrentUserData }) => {

    const [basictooltip, setbasictooltip] = useState(false);
    const toggle = () => setbasictooltip(!basictooltip);

    const { balanceAvailable } = useContext(UserContext);

    const { createWithdraw } = useContext(TransactionContext);

    const [amountAMD, setAmountAMD] = useState('');
    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState(false);
    const openModal = () => {
        setAmountAMD('');
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const onSubmit = async () => {
        if (!!amountAMD) {
            if (amountAMD <= balanceAvailable) {
                setLoading(true);
                let result = await createWithdraw(amountAMD);
                setLoading(false);
                if (true == result) {
                    toast.success("Request created");
                    getCurrentUserData();
                    closeModal();
                } else {
                    toast.error('Something went wrong...');
                }
            } else {
                setLoading(false);
                toast.error('Amount in AMD must not be more than available balance');
            }
        } else {
            toast.error('Amount in AMD must be positive number');
        }
    }

    return (
        <Fragment>
            <Card className='ribbon-wrapper'>
                <CardBody>
                    <Ribbon attrRibbons={{ className: 'ribbon ribbon-clip ribbon-success' }}>
                        Profit Balance
                        <Badges attrBadge={{ color: 'light', className: 'badge rounded-pill mx-2 txt-primary cursor-pointer', id: 'ProfitBalanceTooltip', pill: true }} ><b className='f-w-900'>?</b></Badges>
                    </Ribbon>
                    <ToolTip
                        attrToolTip={{
                            innerClassName: 'bg-light txt-success',
                            placement: 'top',
                            isOpen: basictooltip,
                            target: 'ProfitBalanceTooltip',
                            toggle: toggle
                        }} >
                        (Available for withdraw)
                    </ToolTip>
                    <div className='d-flex align-items-center flex-wrap' style={{ gap: '20px' }}>
                        <b className="h2 m-0 txt-success">֏{balanceAvailable}</b>
                        <button className="btn btn-success px-2" onClick={openModal}><i className="icofont icofont-money h3"></i></button>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={closeModal} size='md' centered>
                <ModalHeader toggle={closeModal}>
                    Available for withdraw <big className='txt-success'>֏<u>{balanceAvailable}</u></big>
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="row mx-0">
                        <Label className="col-sm-3 col-form-label">{'AMD'}</Label>
                        <Col sm="9">
                            <Input className="form-control digits" type="number" min="0" placeholder="Amount AMD" value={amountAMD} onChange={(e) => { setAmountAMD(e.target.value, 'AMD') }} />
                        </Col>
                    </FormGroup>
                    <div className='row mx-0'>
                        <div className='col-12 mb-2'>
                            <small className='txt-dark'><i className="icofont icofont-info-circle"></i> Payment will be created in AMD currency!</small>
                        </div>
                        <div className='col-12'>
                            <div className='form-footer d-flex align-items-center'>
                                <Btn attrBtn={{ onClick: onSubmit, className: 'btn-block btn-lg', color: 'primary' }}>{'Submit'}</Btn>
                                {loading ?
                                    <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                                        <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment >
    );
};

export default BalanceAvailable;
