import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Modal, Row } from "reactstrap";
import { Btn, H3, LI, P, UL } from "../../../AbstractElements";
import { Availability, Brand, BuyNow } from "../../../Constant";
import ProductContext from "../../../_helper/Ecommerce/Product";
import ProductModal from "../Common/ProductModal";
import HeaderCard from './../../Common/Component/HeaderCard';
import SocialIcons from "./SocialIcons";
import StarRatings from "./StarRating";

const ProductDetails = () => {

  const { productItem, symbol } = useContext(ProductContext);
  const [productss, setProductss] = useState("");
  const path = window.location.pathname.split("/").pop();

  const [dataid, setDataid] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const onOpenProductModal = (productId) => {
    setOpenProductModal(true);
    setDataid(productId);
  };

  const [openPayModal, setOpenPayModal] = useState(false);
  const onOpenPayModal = (productId) => {
    setOpenPayModal(true);
  };

  useEffect(() => {
    productItem.map((item) => {
      if (item.id === path) {
        setProductss(item);
      }
      return null;
    });
  });

  const quantity = 1;
  const history = useNavigate();

  const makeTransaction = (item, quantity) => {
    onOpenPayModal();
  };

  return (
    <Fragment>
      <Col xxl="5" className="box-col-6 order-xxl-0 order-1">
        <Card>
          <CardBody>
            <div className="product-page-details">
              <H3>{"Load #258"}</H3>
            </div>
            <div className="product-price">
              {symbol}
              {"100"}
              <del>
                {symbol}
                {"350.00"}
              </del>
            </div>
            <UL attrUL={{ className: "product-color flex-row" }}>
              <LI attrLI={{ className: "bg-primary" }}></LI>
              <LI attrLI={{ className: "bg-secondary" }}></LI>
              <LI attrLI={{ className: "bg-success" }}></LI>
              <LI attrLI={{ className: "bg-info" }}></LI>
              <LI attrLI={{ className: "bg-warning" }}></LI>
            </UL>
            <hr />
            <P>{"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that."}</P>
            <hr />
            <div>
              <Col sm='12' xl='12'>
                <Card>
                  <HeaderCard title={`Products`} mainClasses="p-3" />
                  <CardBody className="p-3">
                    <UL attrUL={{ className: 'border-0 simple-list p-0' }}>
                      <LI attrLI={{ className: 'border-0 list-group-item-action py-2 px-0 m-0', tag: 'p', active: false }}>
                        <Button color="primary" data-toggle="modal" onClick={() => onOpenProductModal(7)}>
                          Man's Jacket
                        </Button>
                      </LI>
                      <LI attrLI={{ className: 'border-0 list-group-item-action py-2 px-0 m-0', tag: 'p', active: false }}>
                        <Button color="primary" data-toggle="modal" onClick={() => onOpenProductModal(8)}>
                          Cyclamen
                        </Button>
                      </LI>
                    </UL>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div>
              <table className="product-page-width">
                <tbody>
                  <tr>
                    <td>
                      <b>{Brand} &nbsp;&nbsp;&nbsp;:</b>
                    </td>
                    <td>{"Pixelstrap"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{Availability} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                    </td>
                    <td className="txt-success">{"in stock"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{"Seller"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                    </td>
                    <td>{"ABC"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{"Fabric"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                    </td>
                    <td>{"Cotton"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <Row>
              <SocialIcons />
            </Row>
            <hr />
            <Row>
              <Col md="4">
                <h6 className="product-title">{"Rate Now"}</h6>
              </Col>
              <Col md="8">
                <StarRatings />
              </Col>
            </Row>
            <hr />
            <div>
              {/* <Button color="primary" className="m-r-10 m-t-10" onClick={() => AddToCarts(singleItem[0], quantity)}>
                <i className="fa fa-shopping-basket me-1"></i>
                {AddToCart}
              </Button> */}
              <Button color="success" className="m-r-10 m-t-10" onClick={() => makeTransaction(productss, quantity)}>
                <i className="fa fa-shopping-cart me-1"></i>
                {BuyNow}
              </Button>
              {/* <Button color="secondary" className="m-t-10" onClick={() => addWishList(singleItem)}>
                <i className="fa fa-heart me-1"></i>
                {"Add To WishList"}
              </Button> */}
            </div>
          </CardBody>
        </Card>
      </Col>
      {openProductModal && <ProductModal value={openProductModal} setOpenModal={setOpenProductModal} dataid={dataid} />}
      {openPayModal && <>
        <Modal className='modal-dialog modal-lg modal-dialog-centered product-modal' isOpen={openPayModal}>
          <div className='modal-body'>
            <div className='modal-header'>
              <div className='modal-title'>
                <div className='product-box row'>
                  <Col lg='12'>
                    <Card>
                      <HeaderCard title={`Invest to this Load`} mainClasses="p-3" />
                      <CardBody className="p-3">
                        <Row>
                          <Col lg='12'>
                            <label>USD <input type="number" placeholder="" /></label>
                          </Col>
                          <Col lg='12'>
                            <label>AMD <input type="number" placeholder="" /></label>
                          </Col>
                          <Col lg='12'>
                            <label>RUB <input type="number" placeholder="" /></label>
                          </Col>
                          <Col lg='12'>
                            <label>EUR <input type="number" placeholder="" /></label>
                          </Col>
                          <Col lg='12'>
                            <Button color="success" className="m-r-10 m-t-10" onClick={() => { console.log('invest'); setOpenPayModal(false) }}>
                              <i className="fa fa-shopping-cart me-1"></i>
                              {`Invest`}
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </div>
              <Btn attrBtn={{ color: 'transprant', className: 'btn-close', onClick: () => { setOpenPayModal(false) }, type: 'button', databsdismiss: 'modal', arialabel: 'Close' }}></Btn>
            </div>
          </div>
        </Modal>
      </>
      }
    </Fragment>
  );
};
export default ProductDetails;
