import axios from 'axios';
import React, { useState } from 'react';
import { RatingsApi } from '../../api';
import Context from './index';

const RatingsProvider = (props) => {
    const [ratings, setRatings] = useState({
        USD: 400,
        RUB: 5,
        AMD: 1,
    });

    const getAllRatingsData = async () => {
        try {
            await axios.get(RatingsApi.getAll).then((resp) => {
                let newRatings = {
                    USD: parseFloat(resp.data.USD),
                    RUB: parseFloat(resp.data.RUB),
                    AMD: 1,
                }
                setRatings(newRatings);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    return (
        <Context.Provider
            value={{
                ...props,
                getAllRatingsData,
                ratings,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default RatingsProvider;