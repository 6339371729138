import React from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
// import ChartistProvider from './_helper/Chartist/ChartistProvider';
import ChartjsProvider from './_helper/Chartjs/ChartProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import FilterProvider from './_helper/Ecommerce/Filter/FilterProvider';
import GoogleChartProvider from './_helper/GoogleChart/GoogleChartProvider';
import LoadProvider from './_helper/Load/LoadProvider';
import RatingsProvider from './_helper/Ratings/RatingsProvider';
import TransactionProvider from './_helper/Transactions/TransactionProvider';
import UserProvider from './_helper/User/UserProvider';

const App = () => (
  <div className='App'>
    <CustomizerProvider>
      <FilterProvider>
        <TransactionProvider>
          <LoadProvider>
            <UserProvider>
                <RatingsProvider >
                  <GoogleChartProvider>
                    <ChartjsProvider>
                      {/* <ChartistProvider> */}
                      <AnimationThemeProvider>
                        <Routers />
                      </AnimationThemeProvider>
                      {/* </ChartistProvider> */}
                    </ChartjsProvider>
                  </GoogleChartProvider>
                </RatingsProvider>
            </UserProvider>
          </LoadProvider>
        </TransactionProvider>
      </FilterProvider>
    </CustomizerProvider>
  </div>
);

export default App;
