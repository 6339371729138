import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import { Spinner } from '../AbstractElements';
import TransactionContext from "../_helper/Transactions";

const PaymentResult = () => {
    const history = useNavigate();

    let { id } = useParams();
    const { checkTransactionStatus, transactionStatus } = useContext(TransactionContext);
    useEffect(() => {
        if (!!id) {
            checkTransactionStatus(id);
        }
    }, [id]);

    const returnToFinances = () => {
        history(`${process.env.PUBLIC_URL}/finances`);
    }

    return (
        <Fragment>
            <Container fluid={true} className="p-0 login-page" style={{ height: '100vh', minHeight: '500px' }}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <Card>
                        <CardBody className="text-center">
                            {'paid' == transactionStatus ?
                                <>
                                    <div className="display-1 txt-success mb-2"><i className="icofont icofont-check-circled"></i></div>
                                    <h3 className="txt-success mb-3">Success</h3>
                                    <h1 className="mb-3">Transaction #{id} done</h1>
                                </>
                                : ''}
                            {'failed' == transactionStatus ?
                                <>
                                    <div className="display-1 txt-danger mb-2"><i className="icofont icofont-close-circled"></i></div>
                                    <h3 className="txt-danger mb-3">Failure</h3>
                                    <h1 className="mb-3">Transaction #{id} Failed</h1>
                                </>
                                : ''}

                            {'rejected' == transactionStatus ?
                                <>
                                    <div className="display-1 txt-danger mb-2"><i className="icofont icofont-close-circled"></i></div>
                                    <h3 className="txt-danger mb-3">Rejected</h3>
                                    <h1 className="mb-3">Transaction #{id} Rejected</h1>
                                </>
                                : ''}
                            {'pending' == transactionStatus ?
                                <>
                                    <div className="display-1 txt-info mb-2"><i className="icofont icofont-pause"></i></div>
                                    <h3 className="txt-info mb-3">Pending</h3>
                                    <h1 className="mb-3">Transaction #{id} Pending</h1>
                                </>
                                : ''}
                            {'in_progress' == transactionStatus ?
                                <>
                                    <div className="display-1 txt-warning mb-2"><i className="icofont icofont-play-alt-1"></i></div>
                                    <h3 className="txt-warning mb-3">In progress</h3>
                                    <h1 className="mb-3">Transaction #{id} In progress</h1>
                                </>
                                : ''}
                            {'' == transactionStatus ?
                                <>
                                    <div className="loader-box d-inline-flex mb-3" style={{ width: '90px', height: '90px' }}>
                                        <Spinner attrSpinner={{ style: { width: '70px', height: '70px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                                    </div>
                                </>
                                : ''}
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: '20px' }}>
                                <button className="btn btn-primary" onClick={returnToFinances}>Return to Finances</button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </Fragment>
    );
}

export default PaymentResult;
