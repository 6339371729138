import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import UserContext from '../../../_helper/User';
import Balance from './Balance';
import BalanceAvailable from './BalanceAvailable';
import TransactionsTable from './TransactionsTable';

const FinancesContain = () => {
    const { getCurrentUserData } = useContext(UserContext);
    useEffect(() => {
        getCurrentUserData();
    }, [getCurrentUserData]);
    return (
        <Fragment>
            <Breadcrumbs parent="Dashboard" title="Finances" mainTitle="Finances" />
            <Container fluid={true}>
                <Row>
                    <Col sm="6">
                        <Balance />
                    </Col>
                    <Col sm="6">
                        <BalanceAvailable getCurrentUserData={getCurrentUserData} />
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <TransactionsTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default FinancesContain;