export const MENUITEMS = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard`, icon: "home", type: "link", title: "Dashboard" },
      { path: `${process.env.PUBLIC_URL}/finances`, icon: "", title: "Finances", type: "link" },
      { path: `${process.env.PUBLIC_URL}/delivery`, icon: "job-search", type: "link", title: "Delivery" },
      { path: `${process.env.PUBLIC_URL}/investments`, icon: "orders", type: "link", title: "Investments" },
    ],
  },

];
