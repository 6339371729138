import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { Email } from '../../../Constant';
import UserContext from '../../../_helper/User';
import EditPersonalData from './EditPersonalData';
import UserImage from './UserImage';

const UserProfile = () => {

  const { firstName, lastName, username, email, phone } = useContext(UserContext);

  const [personalDataModal, setPersonalDataModal] = useState(false);
  const openPersonalDataModal = () => {
    setPersonalDataModal(true);
  };

  const closePersonalDataModal = () => {
    setPersonalDataModal(false);
  };
  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard'>
          {/* <CardHeader className=''>
            <H4 attrH4={{ className: 'card-title mb-0 text-start' }}>Name</H4>
          </CardHeader> */}
          <CardBody>
            <Row>
              <Col>
                <UserImage />
              </Col>
              <Col sm='6' lg='8' className='flex-grow-1 d-flex align-items-center justify-content-between'>
                <div className='info p-0'>
                  <div className='user-designation'>
                    <div className='title text-start text-uppercase' style={{ fontWeight: 500 }}>
                      {firstName} {lastName}
                    </div>
                  </div>
                </div>
                <Btn attrBtn={{ color: 'info', className: 'btn', type: 'button', onClick: () => { openPersonalDataModal() } }}>Edit</Btn>
              </Col>
            </Row>

            <hr />

            <div className='info p-0'>
              <Row className='step3' style={{ rowGap: '15px' }} data-intro='This is the your details'>
                <Col xs='12' md='6'>
                  <div className='ttl-info text-start'>
                    <H5>
                      <i className='fa fa-envelope me-2' style={{ fontSize: '20px' }}></i> Username
                    </H5>
                    <span>{username}</span>
                  </div>
                </Col>
                <Col xs='12' md='6'>
                  <div className='ttl-info text-start'>
                    <H5>
                      <i className='fa fa-envelope me-2' style={{ fontSize: '20px' }}></i> {Email}
                    </H5>
                    <span>{email}</span>
                  </div>
                </Col>
                <Col xs='12' md='6'>
                  <div className='ttl-info text-start ttl-xs-mt'>
                    <H5>
                      <i className='fa fa-phone me-2' style={{ fontSize: '20px' }}></i>
                      Phone
                    </H5>
                    <span>{phone}</span>
                  </div>
                </Col>

              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Modal isOpen={personalDataModal} toggle={closePersonalDataModal} size='lg' centered>
        <ModalHeader toggle={closePersonalDataModal}>
          Edit My Personal Data
        </ModalHeader>
        <ModalBody>
          <EditPersonalData closePersonalDataModal={closePersonalDataModal} />
        </ModalBody>
      </Modal>
    </Fragment >
  );
};

export default UserProfile;
