import React, { useContext, useEffect, useState } from 'react';
import { LogIn, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import man from '../../../assets/images/dashboard/profile.png';

import { Image, LI, P, UL } from '../../../AbstractElements';
import { Account, LogOut } from '../../../Constant';
import CustomizerContext from '../../../_helper/Customizer';
import UserContext from '../../../_helper/User';

const UserHeader = () => {
  const { getCurrentUserData, imageUrl, firstName, lastName, username } = useContext(UserContext);
  useEffect(() => {
    getCurrentUserData();
  }, []);

  const history = useNavigate();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('Emay Walter');
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));
  const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name);
  }, []);

  const Logout = () => {
    localStorage.removeItem('profileURL');
    localStorage.removeItem('token');
    localStorage.removeItem('auth0_profile');
    localStorage.removeItem('Name');
    localStorage.setItem('authenticated', false);
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media' onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/account/`)}>
        <Image
          attrImage={{
            className: 'b-r-10 m-0',
            src: `${imageUrl ? imageUrl : require('../../../assets/images/user/avatar.jpg')}`,
            alt: '',
            style: {
              width: '32px',
              height: '32px'
            }
          }}
        />
        <div className='media-body'>
          <span>{firstName}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            {username} <i className='middle fa fa-angle-down'></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/account/`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI> */}
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
