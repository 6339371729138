import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from "../Constant";
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/profile.png";

import CustomizerContext from "../_helper/Customizer";
import OtherWay from "./Tabs/LoginTab/OtherWay";
import { ToastContainer, toast } from "react-toastify";
import { LoginApi } from "../api";

const Signin = ({ selected }) => {
  const [email, setEmail] = useState("client");
  const [password, setPassword] = useState("client");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", man);
    localStorage.setItem("Name", "Emay Walter");
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();

    const loginData = {
      username: email,
      password: password,
    };

    axios.post(`${LoginApi}`, loginData)
      .then(res => {

        if (undefined === res.data.token) {
          toast.error("You enter wrong password or username!..");
          return;
        }

        const { token, user_nicename, user_email } = res.data;


        setValue(man);
        setName(user_nicename);
        localStorage.setItem("login", JSON.stringify(true));
        history(`${process.env.PUBLIC_URL}/dashboard`);
        toast.success("Successfully logged in!..");

        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('userName', user_nicename);


        // navigate('/dashboard');
        // setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toast.error("You enter wrong password or username!..");
        // toast.error(err.response.data.message);
      })


    // setValue(man);
    // setName("Emay Walter");
    // if (email === "test@gmail.com" && password === "test123") {
    //   localStorage.setItem("login", JSON.stringify(true));
    //   history(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
    //   toast.success("Successfully logged in!..");
    // } else {
    //   toast.error("You enter wrong password or username!..");
    // }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>{"Sign In"}</H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox d-none">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <a className="link d-none" href="#javascript">
                      {ForgotPassword}
                    </a>
                    <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2", onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
                  </div>
                  {/* <OtherWay /> */}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
